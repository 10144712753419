<template>
  <div>
    <h3 class="mb-4 color-primary-light">Follow the instructions on how to safely package your Dyson product </h3>
        <p>Do you have the original manufacturer’s packaging to send your product in?</p>
        <div class="container mt-5">
            <button v-on:click="choose('yes');" class="btn btn-primary btn-lg mr-2">Yes</button>
            <button v-on:click="choose('no');" class="btn btn-danger btn-lg">No</button>
        </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
    }
  },
  props: {
    device: {
      default: null,
    },
  },
    methods: {
        choose(pick) {
          if(pick !== '') {
            this.$router.push({
                query: { type: pick },
                name:'package-dyson'
            });
          }
        },
    },
};
</script>
